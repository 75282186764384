import * as React from 'react'
import { graphql } from 'gatsby'

export async function config() {
    const { data } = graphql`
        {
            oldPosts: allMarkdownRemark(
                filter: { frontmatter: { date: { lt: "2021-10-31" } } }
            ) {
                nodes {
                    fields {
                        slug
                    }
                }
            }
        }
    `

    const oldPosts = new Set(data.oldPosts.nodes.map(n => n.fields.slug))

    return ({ params }) => {
        return {
            defer: oldPosts.has(params.fields__slug),
        }
    }
}

export default function Component(props) {
    return <pre>{JSON.stringify(props, null, 2)}</pre>
}

export const query = graphql`
    query ($id: String) {
        markdownRemark(id: { eq: $id }) {
            html
            fields {
                slug
            }
            frontmatter {
                date
            }
        }
    }
`
